import "bootstrap";
import "./_bootstrap";

import "laravel-datatables-vite";
import select2 from "select2";

select2();

import.meta.glob(["../icons/**"]);
import { createApp, defineAsyncComponent } from "vue";
import { i18nVue } from "laravel-vue-i18n";
import { setComponents } from "@js/setComponents.js";

window.vueApp = createApp({});
window.vueApp = setComponents(window.vueApp);
window.vueApp.use(i18nVue, {
    resolve: async (lang) => {
        const langs = import.meta.glob("../../public/lang/*.json");
        return await langs[`../../public/lang/${lang}.json`]();
    },
});
window.vueApp.mount("#app");

window.postDataVue = (eventType, data) => {
    document.dispatchEvent(
        new CustomEvent("PostDataVueEvent", {
            detail: {
                type: eventType,
                data: data,
            },
        })
    );
};
