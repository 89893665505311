import { defineAsyncComponent } from "vue";

export const setComponents = (vueApp) => {
    return vueApp
        .component(
            "AllTeacherReport",
            defineAsyncComponent(() => import("@js/pages/AllTeacherReport.vue"))
        )
        .component(
            "AllManageReport",
            defineAsyncComponent(() => import("@js/pages/AllManageReport.vue"))
        )
        .component(
            "TeachersTimes",
            defineAsyncComponent(() => import("@js/pages/TeachersTimes.vue"))
        )
        .component(
            "UserSchedules",
            defineAsyncComponent(() => import("@js/pages/UserSchedules.vue"))
        )
        .component(
            "SettingSchedules",
            defineAsyncComponent(() => import("@js/pages/SettingSchedules.vue"))
        )
        .component(
            "SettingWorkSchedules",
            defineAsyncComponent(() =>
                import("@js/pages/SettingWorkSchedules.vue")
            )
        )
        .component(
            "AddClassForm",
            defineAsyncComponent(() =>
                import("@js/components/classes/AddClassForm.vue")
            )
        )
        .component(
            "EditClassForm",
            defineAsyncComponent(() =>
                import("@js/components/classes/EditClassForm.vue")
            )
        )
        .component(
            "AllUserSchedules",
            defineAsyncComponent(() => import("@js/pages/AllUserSchedules.vue"))
        )
        .component(
            "OneOnOneShow",
            defineAsyncComponent(() =>
                import("@js/components/schedule/OneOnOneShow.vue")
            )
        )
        .component(
            "GroupClassShow",
            defineAsyncComponent(() =>
                import("@js/components/schedule/GroupClassShow.vue")
            )
        )
        .component(
            "AttendShow",
            defineAsyncComponent(() => import("@js/components/attend/show.vue"))
        )
        .component(
            "AttendanceReport",
            defineAsyncComponent(() =>
                import("@js/pages/reports/attendance.vue")
            )
        );
};
